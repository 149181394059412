import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BadRequest } from '@core/http/models/bad-request';
import { catchError } from 'rxjs/operators';
import { NotFound } from '@core/http/models/not-found';
import { Unauthorized } from '@core/http/models/unauthorized';
import { Conflict } from '@core/http/models/conflict';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Forbidden } from '@core/http/models/forbidden';
import { InternalServerError } from '@core/http/models/internal-server-error';
import { ExternalApiNotAvailableError } from '@core/http/models/external-api-not-available.error';

@Injectable()
export class HttpResponseCodeHandlerInterceptor implements HttpInterceptor {
  constructor(private nzModalService: NzModalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((e) => {
        if (e instanceof HttpErrorResponse && e.status === 400) {
          return throwError(new BadRequest(e.error));
        }

        if (e instanceof HttpErrorResponse && e.status === 404) {
          return throwError(new NotFound(e.url));
        }

        if (e instanceof HttpErrorResponse && e.status === 401) {
          return throwError(new Unauthorized());
        }

        if (e instanceof HttpErrorResponse && e.status === 403) {
          this.nzModalService.warning({
            nzTitle: 'Ошибка доступа',
            nzContent: 'У Вас нет прав на совершение данного действия',
          });

          return throwError(new Forbidden());
        }

        if (e instanceof HttpErrorResponse && e.status === 409) {
          return throwError(new Conflict());
        }

        if (e instanceof HttpErrorResponse && e.status === 409) {
          return throwError(new Conflict());
        }

        if (e instanceof HttpErrorResponse && e.status === 500) {
          const error = new InternalServerError(e.headers.get('RequestId'), e.error.message);
          return throwError(error);
        }

        if (e instanceof HttpErrorResponse && e.status === 503 && e.error.code == 'externalApiNotAvailable') {
          return throwError(new ExternalApiNotAvailableError(e.error.message, e.error.innerError));
        }

        return throwError(e);
      }),
    );
  }
}
