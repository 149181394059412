import { SignalRHubBaseBase } from '@backend/shared/signalr/signalr-hub-base.service';
import { Injectable, NgZone } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HealthApiHub extends SignalRHubBaseBase<{}> {
  constructor(ngZone: NgZone) {
    super('/health/hub', ngZone);
  }

  setListeners(): void {}
}
