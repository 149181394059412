import { Component } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'rais-api-unavailable.modal',
  template: `
    <nz-alert
      nzShowIcon
      nzType="error"
      [nzDescription]="alertBody"
      nzMessage="Отсутствует соединение с сервером"
    ></nz-alert>

    <ng-template #alertBody>
      <h3></h3>
      Ожидайте подключение...
    </ng-template>

    <div class="modal-footer"></div>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class ApiUnavailableModalComponent {}
