import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { SessionService } from './core/session/store/session.service';
import { SessionQuery } from './core/session/store/session.query';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, map } from 'rxjs/operators';
import { Unauthorized } from '@core/http/models/unauthorized';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private router: Router,
    private nzNotificationService: NzNotificationService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.sessionQuery.isLoggedIn) {
      return of(true);
    }

    return this.sessionService.tryRestore().pipe(
      map(() => true),
      catchError((err) => {
        if (err instanceof Unauthorized) {
          return fromPromise(this.tryLogin());
        }

        return throwError(() => err);
      }),
    );
  }

  async tryLogin() {
    this.nzNotificationService.warning('Авторизация', 'Необходимо авторизоваться');
    const loggedUser = await this.sessionService.openLoginModal('login');

    if (!loggedUser) {
      return false;
    }

    return true;
  }
}
