import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import * as fnsLocale from 'date-fns/locale';
import { AuthGuard } from './auth.guard';
import { CoreModule } from './core/core.module';
import { RootGuardGuard } from './root-guard.guard';
import { NZ_DATE_LOCALE, NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { provideNgxMask } from 'ngx-mask';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@backend/shared/http/auth.interceptor';
import { ApiInterceptor } from '@backend/shared/http/api.interceptor';
import { HttpResponseCodeHandlerInterceptor } from '@backend/shared/http/http-response-code-handler.interceptor';
import { IsoStringToDateObjectInterceptor } from '@backend/shared/http/iso-string-to-date-object-interceptor.service';
import { provideNzConfig } from 'ng-zorro-antd/core/config';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { GlobalErrorHandlerService } from '@core/error-handling/global-error-handler.service';
registerLocaleData(ru);

const appRoutes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [RootGuardGuard],
  },
  {
    path: 'main',
    loadChildren: () => import('./routes/main/main.module').then((o) => o.MainModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./routes/settings/settings.module').then((o) => o.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: AppComponent,
    canActivate: [RootGuardGuard],
  },
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: 'always',
      enableTracing: false,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.settings.enablePwa,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CoreModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: ru_RU },
    { provide: NZ_DATE_LOCALE, useValue: fnsLocale.ru },
    provideNgxMask(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseCodeHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IsoStringToDateObjectInterceptor, multi: true },
    provideNzConfig({
      notification: {
        nzPlacement: 'bottomRight'
      }
    }),
    provideAngularSvgIcon(),
    provideExperimentalZonelessChangeDetection(),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
