import { Component, inject, NgZone, OnInit } from '@angular/core';
import { fromEvent, merge, of, switchMap, timer } from 'rxjs';
import { bufferTime, catchError, filter, map } from 'rxjs/operators';
import { SessionService } from '@core/session/store/session.service';
import { SessionQuery } from '@core/session/store/session.query';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../environments/environment';
import { UserHubService } from '@core/session/hub/user-hub.service';
import { HealthApiHub } from '@core/health-check/health-api.hub';
import { ApiUnavailableModalComponent } from '@core/health-check/api-unavailable.modal/api-unavailable.modal.component';
import { SystemStatusApiService } from '@backend/api/system-status/system-status-api.service';
import { BlockingLoaderComponent, BlockingLoaderRef } from '@component/ui-components/blocking-loader.component';
import { GlobalAppApi } from '@features/global/api/global-app.api';
import { IcuDay } from '@features/process/intensive-care/icu-day';

@Component({
  selector: 'rais-app-root',
  template: ` <router-outlet></router-outlet> `,
  styles: [
    `
      :host {
        height: 100vh;
        width: 100vw;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  private healthApiHub = inject(HealthApiHub);
  private systemStatusApi = inject(SystemStatusApiService);
  private blockingLoader = BlockingLoaderComponent.factory();
  private globalAppApi = inject(GlobalAppApi);
  constructor(
    private sessionService: SessionService,
    private sessionQuery: SessionQuery,
    private userHub: UserHubService,
    private nzNotificationService: NzNotificationService,
    private router: Router,
    private nzModalService: NzModalService,
    private ngZone: NgZone,
  ) {
    // this.setupIdleHooks();

    this.setupDevEnvironmentPatches();
    if (environment.settings.enableHealthCheck) {
      this.setupHealthCheck();
    }

    this.globalAppApi.get().subscribe({
      next: (settingsState) => IcuDay.setStartOfDayOffset(settingsState.settings.icu.icuDayStartOffset),
    });
  }

  setupIdleHooks() {
    merge(fromEvent(document, 'mousemove'), fromEvent(document, 'keypress'))
      .pipe(
        bufferTime(5 * 60 * 1000), //  5 минут
        filter(() => this.sessionQuery.isLoggedIn === true && this.sessionQuery.timeoutEnabled),
        filter((o) => o.length === 0),
      )
      .subscribe((o) => {
        this.sessionService.openLoginModal('timeout');

        this.nzNotificationService.info('Выход из системы', 'Выход из системы по причине неактивности пользователя');
      });
  }

  setupUserUpdateSubscription() {
    this.sessionQuery.user$.subscribe((user) =>
      this.userHub.subscribe({
        id: user?.id ?? 0,
      }),
    );
    // this.userHub.updated.subscribe((x) => this.sessionService.upsert(x));
  }

  ngOnInit() {
    this.userHub
      .connect()
      .then(() => this.setupUserUpdateSubscription())
      .catch((err) => console.log('Failed to connect to user hub: ', err));
  }

  setupDevEnvironmentPatches() {
    window['patchEnvironment'] = (val: any) => {
      environment.settings = {
        ...environment.settings,
        ...val,
      };

      return environment.settings;
    };

    window['getEnvironment'] = () => {
      return environment.settings;
    };
  }

  private _apiUnavailableModal: NzModalRef<any>;

  setupHealthCheck() {
    const openErrorModal = () => {
      this._apiUnavailableModal = this.nzModalService.create({
        nzContent: ApiUnavailableModalComponent,
        nzMaskClosable: false,
        nzCloseIcon: null,
      });
    };

    let loaderRef: BlockingLoaderRef;
    const healthCheck$ = this.systemStatusApi.healthCheck().pipe(
      map(() => 'ok'),
      catchError(() => of('failed')),
    );

    timer(0, 3000)
      .pipe(switchMap(() => healthCheck$))
      .subscribe({
        next: (result) => {
          if (result == 'ok') {
            if (loaderRef) {
              loaderRef.closeSuccess();
            }
          }

          if (result == 'failed') {
            if (!loaderRef) {
              loaderRef = this.blockingLoader({
                message: 'Нет соединения с сервером',
              });
            }
          }
        },
      });
  }
}
