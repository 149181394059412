import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionModule } from './session/session.module';
import { HttpModule } from './http/http.module';
import { GlobalErrorHandlerService } from '@core/error-handling/global-error-handler.service';

const sharedImports = [SessionModule, HttpModule];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...sharedImports],
  exports: [...sharedImports],
  providers: [

  ],
})
export class CoreModule {}
