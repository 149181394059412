import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { HasClaimPipe } from '@core/session/pipes/has-claim.pipe';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { UserModalComponent } from './components/user-modal/user-modal.component';
import { TextInputComponent } from '@app/ui-kit/form/text-input.component';
import { InputWrapperComponent } from '@app/ui-kit/form/input-wrapper.component';

@NgModule({
  declarations: [HasClaimPipe, LoginModalComponent, UserModalComponent],
  exports: [HasClaimPipe],
  imports: [CommonModule, SharedModule, TextInputComponent, InputWrapperComponent]
})
export class SessionModule {}
