import { SignalRHubBase } from '@backend/shared/signalr/signalr-hub-base.service';
import { User } from '@core/session/models/user';
import { Injectable, NgZone } from '@angular/core';
import { guid } from '@datorama/akita';

@Injectable({
  providedIn: 'root',
})
export class UserHubService extends SignalRHubBase<{ id: number }, User> {
  constructor(ngZone: NgZone) {
    super(`/hubs/user?connectionId=${guid()}`, ngZone);
  }

  public setListeners(): void {
    this.connection.on('updated', (updated: User) => {
      this.updated$.next(updated);
    });
  }
}
