import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionQuery } from '@core/session/store/session.query';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private query: SessionQuery,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });

    if (this.query.asSignal$().session) {
      req = req.clone({
        headers: req.headers.set('X-RAIS-SessionId', this.query.asSignal$().session?.id),
      });
    }

    return next.handle(req);
  }
}
