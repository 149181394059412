import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('svg')) return next.handle(req);
    if (req.url.includes('internal/metadata')) return next.handle(req);
    if (req.url.includes('driver-manager/')) {
      if (environment.settings.driverManagerBaseUrl) {
        req = req.clone({
          url: `${environment.settings.driverManagerBaseUrl}${req.url.replace('driver-manager/', '')}`,
        });
      }
    } else if (environment.settings.baseUrl) {
      if (req.url.includes('external/mvs')) {
        req = req.clone({ url: `${environment.settings.baseUrl.replace('/api', '')}${req.url}` });
      } else {
        req = req.clone({ url: `${environment.settings.baseUrl}${req.url}` });
      }
    } else {
      req = req.clone({ url: `/backend/api/${req.url}` });
    }

    return next.handle(req);
  }
}
