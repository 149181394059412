import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { environment } from './environments/environment';
import './app/shared/array/array';
import { environmentLoader } from './environments/environment-loader';
import { AppModule } from 'app/app.module';

void environmentLoader.then((env) => {
  environment.settings = env.settings;

  if (environment.settings.production) {
    enableProdMode();
    enableAkitaProdMode();
    console.log('Production console logging is disabled');
    console.log('Считаешь, что можешь разобраться и что-то улучшить? Пиши gtihonov@quattrolab.ru ;)');
    window.console.log = () => {};
    window.console.debug = () => {};
  }

  // enableProdMode();
  persistState({
    include: ['user'],
    key: 'user',
  });

  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      ngZone: 'noop'
    })
    .catch((err) => console.error(err));
});
